<template>
  <div class="author-container">
    <div class="title">הפוסטים של - {{ getAuthor.name }}</div>
    <div class="post-list-container" v-for="(post, index) in posts" :key="index">
      <PostsItem :post="post"></PostsItem>
    </div>
  </div>
</template>
<script>
// import userSvc from "../services/user";
import postSvc from "../services/post";
import PostsItem from "../components/PostsItem";

import { mapGetters } from "vuex";
export default {
  data() {
    return {
      author: {},
      posts: []
    };
  },
  components: {
    PostsItem
  },
  computed: {
    ...mapGetters("author", ["getAuthor"])
  },
  created() {
    let self = this;
    // console.log(this.getAuthor);
    postSvc.getAuthorPosts(this.getAuthor.id, 30).then(res => {
      self.posts = res.data.map(item => postSvc.postFormater(item.data));
      document.title = 'הפוסטים של ' + this.getAuthor.name;
      // console.log(self.posts);
    });

    // this.author.name=  this.getAuthor.name;
    //   userSvc.getUsersPost
  }
};
</script>
<style lang="scss">
.author-container {
  .title {
    // text-align: right;
    direction: rtl;
  }
}
</style>
