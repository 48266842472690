<template>
  <div class="post-list-container">
    <div v-for="(post, index) in posts" :key="index">
      <PostsItem :post="post"></PostsItem>
    </div>
  </div>
</template>

<script>
import postSvc from "../services/post";
import PostsItem from "../components/PostsItem";
// import  {Observable} from "rxjs/Observable"
export default {
  data() {
    return {
      posts: []
    };
  },
  components: {
    PostsItem
  },
  beforeMount() {
    let self = this;
    let tempArr = [];

    postSvc.getLastPosts(5).then(res => {
      // console.log(res);
      tempArr = res.data.map(item => item.data);
      self.posts = tempArr.map(item => postSvc.postFormater(item));
    });
  }
};
</script>

<style lang="scss"></style>
