import Vue from "vue";
import Vuex from "vuex";
import author from "./author";
import category from "./category";
import tag from "./tag";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    author,
    category,
    tag
  }
});

export default store;
