import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Post from "./components/Post.vue";
import Site from "./components/Site.vue";
import Author from "./components/Author.vue";
import Category from "./components/Category.vue";
import About from "./views/About.vue";
import Tag from "./components/Tag.vue";
import AliexpressItemPost from "./components/AliexpressItemPost.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/:post",
      name: "post",
      component: Post
    },
    {
      path: "/sites/:site",
      name: "site",
      component: Site
    },
    {
      path: "/author/:id",
      name: "author",
      component: Author
    },
    {
      path: "/category/:id",
      name: "category",
      component: Category
    },
    {
      path: "/tag/:id",
      name: "tag",
      component: Tag
    },
    {
      path: "/AliexpressItemPost/:postId",
      name: "AliexpressItemPost",
      component: AliexpressItemPost
    },
    {
      path: "/about",
      name: "about",
      component: About
    },
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "*",
      name: "home",
      component: Home
    }
  ]
});
