<template>
  <div class="author-container">
    <div class="title">{{ getCategory.name }}</div>
    <div
      class="post-list-container"
      v-for="(post, index) in posts"
      :key="index"
    >
      <PostsItem :post="post"></PostsItem>
    </div>
  </div>
</template>
<script>
import categorySvc from "../services/category";
import postSvc from "../services/post";
import PostsItem from "../components/PostsItem";

import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      category: { slug: "" },
      posts: []
    };
  },
  components: {
    PostsItem
  },
  computed: {
    ...mapGetters("category", ["getCategory"])
  },
  methods: {
    ...mapActions("category", ["setCategory"]),
    getPostsCategories() {
      let self = this;
      self.category.slug = self.$route.params.id;

      if (self.getCategory.id) {
        categorySvc.getCategoriesPosts(this.getCategory.id, 10).then(res => {
          self.posts = res.data.map(item => postSvc.postFormater(item.data));
        });
      } else {
        categorySvc.getCategoryBySlug(self.category.slug).then(item => {
          // console.log(item.data[0].data.id);
          let category = {
            id: item.data[0].data.id,
            name: item.data[0].data.name,
            slug: item.data[0].data.slug
          };
          self.setCategory(category);
          document.title = category.name;
          categorySvc.getCategoriesPosts(category.id, 5).then(res => {
            self.posts = res.data.map(item => postSvc.postFormater(item.data));
          });
        });
      }
    }
  },
  watch: {
    getCategory() {
      this.getPostsCategories();
    }
  },
  created() {
    this.getPostsCategories();
  }
};
</script>
<style lang="scss">
.author-container {
  .title {
    // text-align: right;
    direction: rtl;
  }
}
</style>
