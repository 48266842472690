export default {
  namespaced: true,
  state: {
    tag: {},
    posts: []
  },
  getters: {
    getTag: state => {
      return state.tag;
    },
    getTagsPosts: state => {
      return state.posts;
    }
  },
  mutations: {
    setTag: (state, val) => {
      state.tag = val;
    },
    setTagPosts: (state, val) => {
      state.posts = val;
    }
  },
  actions: {
    setTag: ({ commit }, val) => {
      commit("setTag", val);
    },
    setTagPosts: ({ commit }, val) => {
      commit("setTagPosts", val);
    }
  }
};
