<template>
    <div style="direction: rtl; display: grid; grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); gap: 10px;">
        <h2>מוצרים נוספים שאולי יעניינו אותך:</h2>
        <router-link :to="`/AliexpressItemPost/${item.id}`" style="text-decoration: none; margin: 10px 10px;"
            v-for="(item, index) in randomItems" :key="index">
            <div>
                <img style="width: 100%;" :src="item.postImage" :alt="item.title" />
                <p>{{ truncateTitle(item.title) }}</p>
            </div>
        </router-link>
    </div>
</template>

<script>
import { firestore } from '../../firebase.js'

export default {
    name: 'AliexpressItemFooter',
    props: {
        numberOfItems: {
            type: Number,
            default: 3
        }
    },
    data() {
        return {
            randomItems: [],
            lastVisible: null,
        };
    },
    methods: {
        handleScroll() {
            const scrollHeight = document.documentElement.scrollHeight;
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            const clientHeight = document.documentElement.clientHeight;

            if (scrollHeight - scrollTop - clientHeight < 200) {
                this.fetchRandomItems();
            }
        },
        // fetchRandomItems() {
        //     let self = this;
        //     let query = firestore.collection('item-post-aliexpress')
        //         .where('status', '==', 'active')
        //         .orderBy('createdDate', 'desc')
        //         .limit(self.numberOfItems);

        //     if (self.lastVisible) {
        //         query = query.startAfter(self.lastVisible);
        //     }

        //     query.get()
        //         .then((snapshot) => {
        //             self.lastVisible = snapshot.docs[snapshot.docs.length - 1];

        //             const newItems = snapshot.docs
        //                 .map(doc => ({ id: doc.id, ...doc.data() }))
        //                 .filter(item => item.content.includes('6b4bf3c8f28b41d98d90bf97ed9611a3'));

        //             self.randomItems = [...self.randomItems, ...newItems];
        //         })
        //         .catch((error) => {
        //             console.log('Error getting document:', error)
        //         });
        // },
        fetchRandomItems() {
            let self = this;
            firestore.collection('item-post-aliexpress')
                .where('status', '==', 'active')
                .orderBy('createdDate', 'desc')
                // .limit(self.numberOfItems)
                .get()
                .then((docs) => {
                    self.randomItems = docs.docs
                        .map(doc => ({ id: doc.id, ...doc.data() }))
                        .filter(item => item.content.includes('6b4bf3c8f28b41d98d90bf97ed9611a3')).slice(0, self.numberOfItems);
                })
                .catch((error) => {
                    console.log('Error getting document:', error)
                });
        },
        truncateTitle(title) {
            return title.length > 20 ? title.slice(0, 20) + '...' : title;
        },
        redirectToItem(itemId) {
            this.$router.push({ name: 'Item', params: { itemId: itemId } });
        },
    },
    beforeMount() {
        this.fetchRandomItems();
    },
    mounted() {
        //this.fetchRandomItems();
        //window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
};
</script>