import axios from "axios";

let api = "https://us-central1-groupit-5220c.cloudfunctions.net/api/";

function getUserById(id) {
  // return  axios(`${api}user/add2cart/${id}`);
  return axios(`${api}user/add2cart/${id}`);
}

function getUsersPost(userId, limit) {
  // return  axios(`${api}user/add2cart/${userId}/${limit}`);
  return axios(`${api}user/add2cart/${userId}/${limit}`);
}

export default {
  getUserById,
  getUsersPost
};
