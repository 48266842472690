<template>
  <div class="card" style="text-align:right; direction:rtl;">
    <router-link style=" text-decoration:none" :to="`/${post.slug}`">
      <h3 style="color:red" v-html="post.title"></h3>
      <div style="color: black; font-weight: 500;" v-html="post.excerpt"></div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    post: {
      type: Object
    }
  },
  created() {
    // console.log(this.$props.post);
  }
};
</script>

<style lang="scss">
@media only screen and (min-width: 850px) {
  .post-list-container {
    .card {
      width: 50%;
      margin: 0 auto;
      border: 1px solid silver;
      border-radius: 5px;
      margin-bottom: 25px;
      padding: 10px 10px;
    }
  }
}

@media only screen and (max-width: 850px) {
  .post-list-container {
    .card {
      width: 90%;
      margin: 0 auto;
      border-bottom: 1px solid silver;
    }
  }
}
.post-list-container {
}
</style>
