import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import firebase from '../firebase.js';


Vue.config.productionTip = false;


// Function to unregister service workers
function unregisterServiceWorkers() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(registrations => {
      for (let registration of registrations) {
        registration.unregister();
      }
    }).catch(error => {
      console.error("Error unregistering service workers:", error);
    });
  }
}

new Vue({
  router,
  store,
  firebase,
  render: h => h(App),
  created() {
    // Unregister service workers when the app is created
    unregisterServiceWorkers();
  }
}).$mount("#app");
