// Import the necessary Firebase modules
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';

// Your Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDaW5waiCRfqTeg_SbnqpdMoynZjGa_mq4",
    authDomain: "add2cart-632.firebaseapp.com",
    databaseURL: "https://add2cart-632.firebaseio.com",
    projectId: "add2cart-632",
    storageBucket: "add2cart-632.appspot.com",
    messagingSenderId: "631286004957",
    appId: "1:631286004957:web:72cdec0bf283fffe1f2f34",
    measurementId: "G-J4WE3R9KC6"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Initialize Firebase Analytics
firebase.analytics();

// Export Firestore
export const firestore = firebase.firestore();



