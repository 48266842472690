import axios from "axios";

let api = "https://us-central1-groupit-5220c.cloudfunctions.net/api/";

function getCategoryById(id) {
  // return  axios(`${api}category/add2cart/${id}`);
  return axios(`${api}category/add2cart/${id}`);
}

function getCategoryBySlug(slug) {
  //return  axios(`${api}category/add2cart/byslug/${slug}`);
  return axios(`${api}category/add2cart/byslug/${slug}`);
}
//app.get("/category/:site/byslug/:categorySlug", (req, res) => {

function getAllCategories(limit) {
  return axios(`${api}category/add2cart/all/${limit}`);
}
function getCategoriesPosts(id, limit) {
  // return  axios(`${api}category/add2cart/${id}/${limit}`);
  return axios(`${api}category/add2cart/${id}/${limit}`);
}

export default {
  getCategoryById,
  getCategoriesPosts,
  getCategoryBySlug,
  getAllCategories
};
