<template>
  <div class="home">
    {{ siteName }}
    <div class="site" v-html="siteContent"></div>
  </div>
</template>

<script>
import siteSvc from "../services/site";
// import mediaSvc from "../services/media";
export default {
  name: "site",
  data() {
    return {
      siteSlug: "",
      siteName: "",
      media: "",
      site: ""
    };
  },
  watch: {
    "$route.params.site": {
      handler: function(nevVal) {
        let self = this;
        self.siteSlug = nevVal;
        self.getSite();
        // console.log(search);
      },
      deep: true,
      immediate: true
    }
  },
  beforeMount() {
    this.getSite();
  },
  methods: {
    getSite() {
      let self = this;
      siteSvc.getSiteByName(self.siteSlug).then(res => {
        // console.log(res);
        self.siteContent = res.data[0].data.content.rendered;
        self.siteName = res.data[0].data.title.rendered;
      });
    }
  }
};
</script>
<style lang="scss">
@media only screen and (max-width: 850px) {
  .site {
    text-align: right;
    direction: rtl;
    width: 100%;
    margin: 0 auto;

    img {
      max-width: 50%;
    }
  }
}

@media only screen and (min-width: 851px) {
  .site {
    text-align: right;
    direction: rtl;
    width: 50%;
    margin: 0 auto;

    img {
      max-width: 50%;
    }
  }
}
</style>
