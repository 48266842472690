export default {
  namespaced: true,
  state: {
    category: {},
    posts: []
  },
  getters: {
    getCategory: state => {
      return state.category;
    },
    getCategoriesPosts: state => {
      return state.posts;
    }
  },
  mutations: {
    setCategory: (state, val) => {
      state.category = val;
    },
    setCategoryPosts: (state, val) => {
      state.posts = val;
    }
  },
  actions: {
    setCategory: ({ commit }, val) => {
      commit("setCategory", val);
    },
    setCategoryPosts: ({ commit }, val) => {
      commit("setCategoryPosts", val);
    }
  }
};
