<template>
  <div>
    <div class="categories-container">
      <!-- <div
        class="show-categories-button"
        @click="showCategoriesBtns = !showCategoriesBtns"
      >
        רשימת קטגוריות
      </div> -->
      <div class="category-btn-container" v-if="showCategoriesBtns">
        <div class="category-btn" @click="goToCategory(cat)" v-for="(cat, index) in categories" :key="index">
          {{ cat.name }}
        </div>
      </div>
    </div>
    <div class="categories-menu" @click="showCategories = !showCategories">
      ...
    </div>
    <div v-if="showCategories">
      <div class="cat-float-menu">
        <div class="category-title">קטגוריות</div>
        <div class="category-btn-container" v-for="(cat, index) in categories" :key="index">
          <div class="category-btn" @click="goToCategory(cat)">
            {{ cat.name }}
          </div>
        </div>
        <div class="close-menu" @click="showCategories = false">X</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      showCategories: false,
      showCategoriesBtns: false,
      categories: []
    };
  },
  methods: {
    ...mapActions("category", ["setCategory", "setCategoryPosts"]),
    goToCategory(category) {
      this.setCategory(category);
      this.$router.push({
        name: "category",
        params: { id: category.slug }
      });
      this.showCategories = !this.showCategories;
    }
  },
  created() {
    // let self = this;
    // categorySvc.getAllCategories(100).then(res => {
    //   this.categories = res.data
    //     .map(item => item.data)
    //     .sort((a, b) => b.count - a.count)
    //     .map(item => {
    //       return { count: item.count, name: item.name, slug: item.slug };
    //     })
    //     .filter(item => item.count > 10)
    //     .slice(0, 100);
    // });
  }
};
</script>
<style lang="scss">
@media only screen and (min-width: 600px) {
  .cat-float-menu {
    display: none;
  }

  .show-categories-button {
    color: blue;
    text-decoration: underline;
    margin-top: 6px;
    cursor: pointer;
  }

  .categories-container {
    width: 50%;
    direction: rtl;
    text-align: right;
    margin: 0 auto;

    .category-btn-container {
      display: flex;
      flex-wrap: wrap;

      .category-btn {
        margin: 6px;
        padding: 4px 10px;
        width: fit-content;
        border-radius: 5px;
        background-color: red;
        color: white;
        cursor: pointer;
      }
    }
  }

  .categories-menu {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .categories-container {
    display: none;
  }

  .categories-menu {
    position: fixed;
    top: 4px;
    right: 6px;
    font-size: 40px;
    border-radius: 5px;
    padding-bottom: 20px;
    padding-right: 10px;
    padding-left: 10px;
    background-color: white;
  }

  .cat-float-menu {
    position: fixed;
    background-color: whitesmoke;
    width: 86%;
    left: 5%;
    top: 2%;
    margin: 0 auto;
    // opacity: 0.96;?
    text-align: right;
    padding: 10px;
    height: 86%;
    overflow: scroll;
    padding-top: 42px;
    // position: relative;

    .close-menu {
      position: fixed;
      top: 24px;
      left: 30px;
    }

    .category-title {
      position: fixed;
      top: 0;
      right: 30px;
      width: 70%;
      margin-top: 12px;
      margin-right: 6px;
      padding-bottom: 22px;
      font-size: 32px;
      background-color: whitesmoke;
    }

    .category-btn {
      margin: 15px;
    }
  }
}
</style>
