import axios from "axios";
import moment from "moment";
// import mediaSvc from "./media";

let api = "https://us-central1-groupit-5220c.cloudfunctions.net/api/";

function postFormat(ObjInit) {
  let self = this;
  // console.log(ObjInit);
  self.slug = ObjInit.slug;
  self.title = ObjInit.title.rendered;
  self.excerpt = ObjInit.excerpt.rendered;
  // mediaSvc.getMediaById(ObjInit.featured_media).then(dat => {
  //   if (!!dat && dat != undefined) {
  //     self.media = dat.data[0].data.media_details.file;
  //     console.log(self.media);

  //   }
  //   else {
  //     self.media = '#';
  //   }
  // }).catch(err => {
  //   console.log('err - ', err)
  // });
  self.categories = ObjInit.categories; //todo categories service
  self.tags = ObjInit.tags; //todo tags service
  self.date = moment(ObjInit.date).format("DD/MM/YYYY");
}

function postFormater(postToFormat) {
  return new postFormat(postToFormat);
}

function getLastPosts(numberOfPosts) {
  // return axios.get(`${api}posts/add2cart/${numberOfPosts}`);
  return axios.get(`${api}posts/add2cart/${numberOfPosts}`);
}

function getAuthorPosts(authorId, numberOfPosts) {
  // return axios(`${api}author/add2cart/${authorId}/${numberOfPosts}`);
  return axios(`${api}author/add2cart/${authorId}/${numberOfPosts}`);
}

function getPostByName(slug) {
  // return axios(`${api}post/add2cart/${slug}`);
  return axios(`${api}post/add2cart/${slug}`);
}

export default {
  getLastPosts,
  getPostByName,
  getAuthorPosts,
  postFormater
};
