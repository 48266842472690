<template>
  <div class="post-container">
    <div class="title" v-html="post.title"></div>
    <!-- <img class="img-post" :src="post.media" /> -->
    <div class="post-meta">
      <div>{{ post.date }}</div>
      <div class="author-link" @click="goToAuthor()">{{ getAuthor.name }}</div>
    </div>
    <div v-if="post.categories.length > 0" class="categories-row">
      <div style="font-weight:bold">קטגוריות:</div>
      <div v-for="(cat, index) in post.categories" :key="index">
        <div class="category-ref" @click="goToCategory(cat)">
          {{ cat.name }}
        </div>
      </div>
    </div>
    <div class="post" v-html="post.content"></div>
    <div v-if="post.tags.length > 0" class="categories-row">
      <div style="font-weight:bold">תגיות:</div>
      <div v-for="(tag, index) in post.tags" :key="index">
        <div class="category-ref" @click="goToTag(tag)">{{ tag.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import postSvc from "../services/post";
// import mediaSvc from "../services/media";
import userSvc from "../services/user";
import categorySvc from "../services/category";
import tagSvc from "../services/tag";
import moment from "moment";

export default {
  name: "post",
  data() {
    return {
      // postTitle: "",
      // postDate:"",
      post: {
        slug: "",
        content: "",
        title: "",
        media: "",
        date: "",
        categories: [],
        tags: []
        // author: { id: "", name: "" }
      }
      // media: ""
    };
  },
  beforeMount() {
    let self = this;
    self.post.slug = self.$route.params.post;

    postSvc.getPostByName(self.post.slug).then(res => {
      // console.log(res);

      // if (res.data[0].data.featured_media) {
      //   mediaSvc.getMediaById(res.data[0].data.featured_media).then(dat => {
      //     self.post.media = require("@/assets/" +
      //       dat.data[0].data.media_details.file);
      //   });
      // }
      if (res.data[0].data.categories) {
        let categories = res.data[0].data.categories;
        let tempCategory = {};
        for (let index = 0; index < categories.length; index++) {
          categorySvc.getCategoryById(categories[index]).then(dat => {
            tempCategory = {
              id: categories[index],
              name: dat.data[0].data.name,
              slug: dat.data[0].data.slug
            };
            // self.setAuthor(res.data[0].data);
            self.post.categories.push(tempCategory);
            document.title = '' + self.post.title;
          });
        }
      }

      if (res.data[0].data.tags) {
        let tags = res.data[0].data.tags;
        let tempTag = {};
        for (let index = 0; index < tags.length; index++) {
          tagSvc.getTagById(tags[index]).then(dat => {
            tempTag = {
              id: tags[index],
              name: dat.data[0].data.name,
              slug: dat.data[0].data.slug
            };
            // self.setAuthor(res.data[0].data);
            self.post.tags.push(tempTag);
          });
        }
      }

      let authId = res.data[0].data.author;
      userSvc.getUserById(authId).then(res => {
        self.setAuthor(res.data[0].data);
      });
      self.post.content = res.data[0].data.content.rendered
        .replace("כתיבת תגובה", "")
        .replace("אולי יעניין אתכם גם", "")
        .replace(
          '<p>רגע רגע, רוצים להמליץ בעצמכם על מוצרים שרכשתם ואפילו לקבל על כך תגמול?<a href="http://emabey.co.il/%D7%A9%D7%99%D7%98%D7%AA-%D7%94%D7%AA%D7%92%D7%9E%D7%95%D7%9C%D7%99%D7%9D-%D7%A9%D7%9C-emabey/" target="_blank">כנסו כנסו</a></p>',
          ""
        );
      self.post.title = res.data[0].data.title.rendered;
      self.post.date = moment(res.data[0].data.date).format("DD/MM/YYYY");

      // console.log(self.post);
    });
  },
  computed: {
    ...mapGetters("author", ["getAuthor"])
  },
  methods: {
    ...mapActions("author", ["setAuthor", "setPosts"]),
    ...mapActions("category", ["setCategory", "setCategoryPosts"]),
    ...mapActions("tag", ["setTag", "setTagPosts"]),
    goToAuthor() {
      let self = this;
      this.$router.push({
        name: "author",
        params: { id: self.getAuthor.slug }
      });
    },
    goToCategory(category) {
      this.setCategory(category);
      this.$router.push({
        name: "category",
        params: { id: category.slug }
      });
    },
    goToTag(tag) {
      this.setTag(tag);
      this.$router.push({
        name: "tag",
        params: { id: tag.slug }
      });
    }
  }
};
</script>
<style lang="scss">
.post-container {
  .author-link {
    color: blue;
    cursor: pointer;
    text-decoration: underline;
  }

  .title {
    font-weight: bold;
    font-size: 14pt;
    direction: rtl;
  }

  .post-meta {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    border-top: 1px solid gray;
  }

  .categories-row {
    display: flex;
    text-align: right;
    direction: rtl;
    margin: 10px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid gray;

    div {
      margin-left: 15px;
    }
  }

  @media only screen and (max-width: 850px) {
    .category-ref {
      color: purple;
      text-decoration: underline;
      cursor: pointer;
    }

    .post,
    .post-meta {
      text-align: right;
      direction: rtl;
      width: 100%;
      margin: 0 auto;

      img {
        max-width: 50%;
      }
    }

    .img-post {
      max-width: 100%;
    }
  }

  @media only screen and (min-width: 851px) {
    .title {
      font-size: 30pt;
    }

    .categories-row {
      width: 50%;
      margin: 10px auto;

      .category-ref {
        color: purple;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .post,
    .post-meta {
      text-align: right;
      direction: rtl;
      width: 50%;
      margin: 0 auto;

      img {
        max-width: 50%;
      }
    }

    .img-post {
      max-width: 50%;
    }
  }
}</style>
