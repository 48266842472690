<template>
  <div id="app">
    <div class="header">
      <router-link to="/">
        <img alt="קניות ברשת וזהו :)" src="@/assets/logo.png" />
      </router-link>
    </div>
    <div>
      <SitesList></SitesList>
      <CategoryList></CategoryList>
    </div>
    <router-view></router-view>
    <AliexpressItemFooter :numberOfItems="10" />
  </div>
</template>

<script>
import CategoryList from "./components/CategoryList";
import SitesList from "./components/SitesList";
import AliexpressItemFooter from '@/components/AliexpressItemFooter.vue'

export default {
  components: {
    CategoryList,
    SitesList,
    AliexpressItemFooter
  },
  // beforeCreate() {
  //   document.title = 'Add2cart - קניות ברשת';
  //   new Image().src = 'https://add2cart.co.il/img/logo.36ebd5b4.png';
  //   new Image().src = '@/assets/logo.png';
  // },
  head: {
    link: [
      {
        rel: 'icon',
        href: require('@/assets/logo.png')
      }
    ]
  }
};
</script>
<style lang="scss">
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

img {
  border-radius: 9%;
}
</style>
