<template>
  <div class="home">
    <PostsList></PostsList>
  </div>
</template>

<script>
import PostsList from "@/components/PostsList.vue";

// import { inject } from 'vue'
export default {
  name: "home",
  // inject: ['messaging'],
  components: { PostsList },
  created() {
    // let path = this.$route.params;
        // console.log('Firebase cloud messaging object', this.$messaging)

  },
  setup() {
    // const messaging = inject('messaging')
    // messaging.getToken({ vapidKey: "BJI6ZqZdLB6spc9mYyR6-YiyOJNbytXhjT_zCRe7ICks7MeAFJLCTCBsynT8b4tPaPMTmqUTMouDxbhI9rmm37c" });
    // console.log('Firebase cloud messaging object', messaging)
  }
};
</script>
