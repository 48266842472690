<template>
    <div style="direction: rtl; text-align: right; margin:0 auto;">
        <div v-html="post.content"></div>
        <div>
            <h3>מילות מפתח:</h3>
            <div>
                <span v-for="(keyword, index) in post.keywords" :key="index">
                    <a @click="redirectToArchive(keyword)">{{ keyword }}</a>
                </span>
            </div>
        </div>
        <div>
            הנתונים נכונים לתאריך {{ formatDate(post.createdDate) }}
        </div>
    </div>
</template>

<script>
import { firestore } from '../../firebase.js' // Import the firestore function from the file we created in Step 2'; 

export default {
    name: 'AliexpressItemPost',
    data() {
        return {
            post: {},
            _postId: null
        }
    },
    watch: {
        '$route': 'fetchPost'
    },
  beforeMount() {
        this.fetchPost();
    },
    methods: {
        fetchPost() {
            window.scrollTo(0, 0);
            const postId = this.$route.params.postId;
            this._postId = postId;
            firestore.collection('item-post-aliexpress').doc(postId).get()
                .then((doc) => {
                    if (doc.exists) {
                        this.post = doc.data()
                        document.title = 'עליאקספרס ' + this.post.title;
                        //document.images = [this.post.postImage];
                    } else {
                        console.log('No such document!')
                    }
                })
                .catch((error) => {
                    console.log('Error getting document:', error)
                });
        },
        formatDate(date) {
            if (date && typeof date.toDate === 'function') {
                date = date.toDate();
            }
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return new Date(date).toLocaleDateString(undefined, options);
        }
    }
}
</script>
