import axios from "axios";

let api = "https://us-central1-groupit-5220c.cloudfunctions.net/api/";

function getTagById(id) {
  // return  axios(`${api}tag/add2cart/${id}`);
  return axios(`${api}tag/add2cart/${id}`);
}

function getTagBySlug(slug) {
  // return  axios(`${api}tag/add2cart/byslug/${slug}`);
  return axios(`${api}tag/add2cart/byslug/${slug}`);
}

function getTagsPosts(id, limit) {
  // return  axios(`${api}tag/add2cart/${id}/${limit}`);
  return axios(`${api}tag/add2cart/${id}/${limit}`);
}

export default {
  getTagById,
  getTagsPosts,
  getTagBySlug
};
