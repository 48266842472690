<template>
  <div>
    <div class="sites-container">
      <!-- <div class="show-sites-button" @click="showSitesBtns = !showSitesBtns">
        רשימת אתרים
      </div> -->

      <div class="site-btn-container" v-if="showSitesBtns">
        <div
          v-for="(site, index) in sites"
          :key="index"
          class="site-btn"
          @click="goToSite(site)"
        >
          {{ site.name }}
        </div>
      </div>
    </div>
    <div class="sites-menu" @click="showSites = !showSites">...</div>
    <div v-if="showSites">
      <div class="site-float-menu">
        <div class="site-title">אתרים</div>
        <div
          class="site-btn-container"
          v-for="(site, index) in sites"
          :key="index"
        >
          <div class="site-btn" @click="goToSite(site)">{{ site.name }}</div>
        </div>
        <div class="close-menu" @click="showSites = false">X</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showSites: false,
      showSitesBtns: false,
      sites: []
    };
  },
  methods: {
    goToSite(site) {
      this.$router.push({
        name: "site",
        params: { site: site.slug }
      });
      this.showSites = !this.showSites;
    }
  },
  created() {
    // let self = this;
    // siteSvc.getAllSites(1000).then(res => {
    //   this.sites = res.data
    //     .map(item => item.data)
    //     .sort((a, b) => a.title.rendered - b.title.rendered)
    //     .map(item => {
    //       return { name: item.title.rendered, slug: item.slug };
    //     })
    //     .slice(0, 100);
    // });
  }
};
</script>
<style lang="scss">
@media only screen and (min-width: 600px) {
  .site-float-menu {
    display: none;
  }
  .sites-container {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    direction: rtl;
    text-align: right;
    margin: 0 auto;

    .show-sites-button {
      color: blue;
      text-decoration: underline;
      cursor: pointer;
    }
    .site-btn-container {
      //
      display: flex;
      flex-wrap: wrap;
      // width: 50%;
      direction: rtl;
      text-align: right;
      margin: 0 auto;
      .site-btn {
        margin: 6px;
        padding: 4px 10px;
        width: fit-content;
        border-radius: 5px;
        background-color: red;
        color: white;
        cursor: pointer;
      }
    }
  }
  .sites-menu {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .sites-container {
    display: none;
  }
  .sites-menu {
    position: fixed;
    top: 4px;
    right: 6px;
    font-size: 40px;
    border-radius: 5px;
    padding-bottom: 20px;
    padding-right: 10px;
    padding-left: 10px;
    background-color: white;
  }
  .site-float-menu {
    position: fixed;
    background-color: whitesmoke;
    width: 86%;
    left: 5%;
    top: 2%;
    margin: 0 auto;
    // opacity: 0.96;?
    text-align: right;
    padding: 10px;
    height: 86%;
    overflow: scroll;
    padding-top: 42px;
    // position: relative;

    .close-menu {
      position: fixed;
      top: 24px;
      left: 30px;
    }
    .site-title {
      position: fixed;
      top: 0;
      right: 30px;
      width: 70%;
      margin-top: 12px;
      margin-right: 6px;
      padding-bottom: 22px;
      font-size: 32px;
      background-color: whitesmoke;
    }
    .site-btn {
      margin: 15px;
    }
  }
}
</style>
