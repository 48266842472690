import axios from "axios";

let api = "https://us-central1-groupit-5220c.cloudfunctions.net/api/";
function getLastSites(numberOfSites) {
  return axios(`${api}sites/add2cart/${numberOfSites}`);
}

function getSiteByName(slug) {
  return axios(`${api}site/add2cart/${slug}`);
}

function getAllSites(limit) {
  return axios(`${api}sites/add2cart/all/${limit}`);
}

export default {
  getLastSites,
  getSiteByName,
  getAllSites
};
