<template>
  <div class="author-container">
    <div class="title">{{ getTag.name }}</div>
    <div
      class="post-list-container"
      v-for="(post, index) in posts"
      :key="index"
    >
      <PostsItem :post="post"></PostsItem>
    </div>
  </div>
</template>
<script>
import tagSvc from "../services/tag";
import postSvc from "../services/post";
import PostsItem from "../components/PostsItem";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      tag: { slug: "" },
      posts: []
    };
  },
  components: {
    PostsItem
  },
  computed: {
    ...mapGetters("tag", ["getTag"])
  },
  methods: {
    ...mapActions("tag", ["setTag"])
  },
  created() {
    let self = this;
    self.tag.slug = self.$route.params.id;

    if (self.getTag.id) {
      tagSvc.getTagsPosts(this.getTag.id, 30).then(res => {
        self.posts = res.data.map(item => postSvc.postFormater(item.data));
      });
    } else {
      tagSvc.getTagBySlug(self.tag.slug).then(item => {
        let tag = {
          id: item.data[0].data.id,
          name: item.data[0].data.name,
          slug: item.data[0].data.slug
        };
        self.setTag(tag);
        tagSvc.getTagsPosts(tag.id, 30).then(res => {
          self.posts = res.data.map(item => postSvc.postFormater(item.data));
        });
      });
    }
  }
};
</script>
<style lang="scss">
.author-container {
  .title {
    // text-align: right;
    direction: rtl;
  }
}
</style>
