export default {
  namespaced: true,
  state: {
    author: {},
    posts: []
  },
  getters: {
    getAuthor: state => {
      return state.author;
    },
    getAuthorPosts: state => {
      return state.posts;
    }
  },
  mutations: {
    setAuthor: (state, val) => {
      state.author = val;
    },
    setPosts: (state, val) => {
      state.posts = val;
    }
  },
  actions: {
    setAuthor: ({ commit }, val) => {
      commit("setAuthor", val);
    },
    setPosts: ({ commit }, val) => {
      commit("setPosts", val);
    }
  }
};
